import { PaymentPlanInstallment, Student } from "../components/billing/Student.model";
import { IMeadowHttpFactory, meadowHttpFactory } from "./meadowHttpFactory";

export type CreatePaymentPlanResponse = {
    paymentPlanId: number;
};
export type Tila = {
    name: string;
    tooltipText: string;
    value: number;
};

export type GetPaymentPlanResponse = {
    fee: number;
    paymentPlanId: number;
    academicYear: number;
    academicTerm: string;
    academicTermDescription: string;
    academicSession: string | undefined;
    totalAmount: number;
    remainingAmount: number;
    installmentsPaid: number;
    installmentsRemaining: number;
    installments: PaymentPlanInstallment[];
    isAutopayEnabled: boolean;
    tila: Tila[];
    feePlusFirstInstallment?: number;
    feePlusPreviousBalance?: number;
    previousBalance: number;
    debug: BillingEngineDebug;
};
export type BillingEngineDebug = {
    periodsOfEnrollment: string[];
    chargeApplicationDebugRecords: string[];
    chargeAllocationBuckets: string[];
    balanceBuckets: string[];
};

export type IPaymentPlanService = {
    createPaymentPlan(student: Student): Promise<CreatePaymentPlanResponse>;
    getPaymentPlan(paymentPlanId: number): Promise<GetPaymentPlanResponse>;
};

export class PaymentPlanService implements IPaymentPlanService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    public async createPaymentPlan(student: Student): Promise<CreatePaymentPlanResponse> {
        const eligiblePaymentPlan = student.eligiblePaymentPlans?.[0];
        const result = await this.http.post(
            `/${import.meta.env.VITE_BILLING_VERSION}/payment_plan`,
            {
                studentId: student.studentId,
                paymentPlanConfigurationId: eligiblePaymentPlan.paymentPlanConfigurationId,
                academicYear: eligiblePaymentPlan.academicYear,
                academicTerm: eligiblePaymentPlan.academicTerm,
                academicSession: eligiblePaymentPlan.academicSession,
            }
        );
        return result.data;
    }

    public async getPaymentPlan(paymentPlanId: number): Promise<GetPaymentPlanResponse> {
        const result = await this.http.get(
            `/${import.meta.env.VITE_BILLING_VERSION}/payment_plan/${paymentPlanId}`
        );
        return result.data;
    }
}
