import * as Sentry from "@sentry/react";
import MeadowContainer from "../container";
import greeting from "../../assets/greeting.svg";
import { getGreeting } from "./greeting";
import { Box, List, SxProps, Typography } from "@mui/material";
import { STUDENT_STATUS } from "../../constants";
import { useContext, useEffect, useState } from "react";
import { GREY_200, GREY_800 } from "../../theme/colors";
import useBreakpoint from "../../hooks/useBreakpoint";
import PoweredByMeadow from "../footer/PoweredByMeadow";
import { BillingService } from "../../services/BillingService";
import { PaymentContext } from "../../providers/PaymentsProvider";
import { PaymentsStudent, PaymentInstrumentType } from "../../reducers/PaymentsReducer";
import DisabledStudentCard from "./disabled/DisabledStudentCard";
import StudentCard from "./student-card/StudentCard";
import { UserContext } from "../../context/UserContext";
import { AddAccountButton } from "../admin/AddAccountButton";
import { WarningBanner } from "./WarningBanner";
import nothingToSeeHere from "../../assets/nothing_to_see_here.svg";
import LoadingDialog from "../modal/LoadingDialog";
import { AuthService } from "../../services/AuthService";
import PaymentPlanCard from "./payment-plans/PaymentPlanCard";
import { determineBanner, verifyAccessControls } from "./utilities";
import PaymentPlanEnrollmentCard from "./payment-plans/PaymentPlanEnrollmentCard";
import { ConfigContext } from "../../context/ConfigContext";

export default function Billing() {
    const { user } = useContext(UserContext);
    const { config } = useContext(ConfigContext);
    const { students, setStudents, reset } = useContext(PaymentContext);
    const { isMobile } = useBreakpoint();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isErrored, setIsErrored] = useState<boolean>(false);
    const cardWrapperSx = {
        borderBottom: `1px solid ${GREY_200}`,
        paddingBottom: "1rem",
        paddingLeft: isMobile ? "1rem" : 0,
        paddingRight: isMobile ? "1rem" : 0,
        paddingTop: 0,
        "&:last-child": {
            borderBottom: "none",
        },
    };

    useEffect(() => {
        const authService = new AuthService();

        async function fetchStudentAccessChallenges(studentId: number) {
            const accessChallenges = await authService.getAccountAccessChallenges(studentId);

            return accessChallenges;
        }
        async function fetchBillingData() {
            try {
                setIsLoading(true);
                const billingService = new BillingService();
                const students = await billingService.getBillingData();
                const studentsWithChallenges = await Promise.all(
                    students.map(async (student) => {
                        const studentRole = user?.students.find(
                            (item) => item.id === student.studentId
                        );
                        // dont fetch the access challenges if the user is an ally to the student
                        if (
                            studentRole &&
                            (studentRole.role === "student" || studentRole.role === "admin")
                        ) {
                            const studentAccountAccessChallenges =
                                await fetchStudentAccessChallenges(student.studentId);
                            return {
                                ...student,
                                accountAccessList: studentAccountAccessChallenges,
                            };
                        } else {
                            return {
                                ...student,
                                accountAccessList: [],
                            };
                        }
                    })
                );

                // Sets defaults for `paymentMode` and `paymentMethodType`, which reset every time we re-fetch from the API.
                // TODO - we need separate layer to map Student to PaymentsStudent
                const studentsWithDefaults: PaymentsStudent[] = studentsWithChallenges.map(
                    (student) => {
                        return {
                            ...student,
                            paymentMode: undefined,
                            partialPaymentAmount: 0,
                            paymentMethodType: PaymentInstrumentType.BANK_ACCOUNT,
                            amountToPay: 0,
                            totalPaid: 0,
                        };
                    }
                );
                setStudents(studentsWithDefaults);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setIsErrored(true);
                Sentry.captureException(err);
            }
        }
        reset();
        fetchBillingData();
    }, []);

    const greetingSx = {
        fontWeight: 500,
        fontSize: "1.75rem",
        lineHeight: "2.25rem",
        display: "flex",
        gap: "0.5rem",
        paddingLeft: isMobile ? "1rem" : 0,
    };
    const wrapperSx = {
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "unset" : "center",
    };

    const addAccountButtonWrapperSx: SxProps = {
        display: "flex",
        justifyContent: "center",
        padding: "1rem",
    };

    return (
        <MeadowContainer
            fullWidth
            noPadding
            sx={{
                backgroundColor: GREY_800,
                height: "100%",
                paddingBottom: "1rem",
            }}
        >
            <Box sx={addAccountButtonWrapperSx}>
                {isMobile && user?.isAdmin ? <AddAccountButton /> : <></>}
            </Box>

            <Box sx={wrapperSx}>
                <Box>
                    <Typography variant="h1" sx={greetingSx}>
                        {getGreeting()} <img src={greeting} />
                    </Typography>

                    <List
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                        }}
                    >
                        {isErrored && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "8.5rem",
                                }}
                            >
                                <WarningBanner
                                    showMessage={isErrored}
                                    message="Account not found. Please try again or contact your school for assistance."
                                />
                                <img
                                    style={{ height: "9.375rem", width: "9.375rem" }}
                                    src={nothingToSeeHere}
                                />
                            </Box>
                        )}
                        {!isErrored && (
                            <>
                                {!isLoading ? (
                                    students.map((student: PaymentsStudent, index: number) => {
                                        return (
                                            <Box sx={cardWrapperSx} key={index}>
                                                {student.status === STUDENT_STATUS.DISABLED ? (
                                                    <DisabledStudentCard student={student} />
                                                ) : (
                                                    <>
                                                        {config &&
                                                            determineBanner(
                                                                student,
                                                                config?.shouldShowDueDates
                                                            )}
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "1rem",
                                                            }}
                                                        >
                                                            <StudentCard student={student} />
                                                            {student.applicablePaymentPlan &&
                                                                student.status !==
                                                                    STUDENT_STATUS.BILLING_DISABLED && (
                                                                    <PaymentPlanCard
                                                                        paymentPlan={
                                                                            student.applicablePaymentPlan
                                                                        }
                                                                    />
                                                                )}
                                                            {student.eligiblePaymentPlans?.length >
                                                                0 &&
                                                                student.status !==
                                                                    STUDENT_STATUS.BILLING_DISABLED && (
                                                                    <PaymentPlanEnrollmentCard
                                                                        student={student}
                                                                    />
                                                                )}
                                                            {user &&
                                                                verifyAccessControls(user, student)}
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                        );
                                    })
                                ) : (
                                    <LoadingDialog open={isLoading} />
                                )}
                            </>
                        )}
                    </List>
                </Box>
            </Box>
            <PoweredByMeadow />
        </MeadowContainer>
    );
}
